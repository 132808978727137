import { DICA_REGISTRIES } from "./dica-registries";

export type RegistryTenant = "dica" | "niv" | "swab" | "pice" | "rivm" | "dicawgz" | "none" | "idr";

export const REGISTRY_TENANTS_LOOKUP: { [tenantId in RegistryTenant]: string[] } = {
    dica: DICA_REGISTRIES,
    niv: ["Draim", "Demo"],
    idr: ["Icra"],
    swab: ["AmsmReporting"],
    pice: ["Pice", "PiceW"],
    rivm: ["Gli"],
    dicawgz: ["DcraWgz", "DhfaWgz"],
    none: ["Born", "BornV3", "DgoaR", "Dpaa", "Hened", "Nbrr3"],
};

export const ALL_REGISTRIES = Object.values(REGISTRY_TENANTS_LOOKUP).reduce(
    (allRegistries, tenantRegistries) => [...allRegistries, ...tenantRegistries],
);
